@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");
*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-color: #00040f;
  font-size: 16px;
}
a {
  text-decoration: none;
}
li {
  list-style: none;
}
.App {
  padding: 1.875rem 8.463125rem;
}
/* Header Components */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hide-right-side {
  display: none;
}
.nav-items {
  display: flex;
  gap: 3.5rem;
}
.nav-item {
  color: #c0b3c5;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.5s linear;
}
.nav-item:hover {
  color: #fff;
}
.drop-down {
  display: none;
}

/* Hero Components */
.hero {
  margin-top: 2rem;
  display: flex;
}
.hero-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
}
.discount {
  padding: 0.25rem 0.75rem;
  background-color: #212124;
  border-radius: 0.625rem;
  width: 25.5rem;
  height: 2.5rem;
  margin-bottom: 0.75rem;
}
.discount-text {
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 200;
}
.discount-text span {
  font-weight: 400;
  margin: 0 0.25rem;
}
.discount-img {
  width: 2rem;
  height: 2rem;
  margin-right: 0.25rem;
}
.hero-info {
  position: relative;
}
.hero-title {
  font-size: 4.5rem;
  line-height: 1.5;
  margin-bottom: 2.5rem;
}
.blue {
  background: radial-gradient(
    64.18% 64.18% at 71.16% 35.69%,
    #def9fa 0.89%,
    #bef3f5 17.23%,
    #9dedf0 42.04%,
    #7de7eb 55.12%,
    #5ce1e6 71.54%,
    #33bbcf 100%
  );
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
.hero-text {
  color: #b3b4b7;
  font-size: 1.125rem;
  width: 25rem;
  font-weight: 300;
}
.hero-btn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  word-wrap: break-word;
  top: 8%;
  left: 70%;
  border-radius: 50%;
  width: 8.75rem;
  height: 8.75rem;
  border: 1px solid;
  font-size: 1.125rem;
  background: radial-gradient(
    64.18% 64.18% at 71.16% 35.69%,
    #def9fa 0.89%,
    #bef3f5 17.23%,
    #9dedf0 42.04%,
    #7de7eb 55.12%,
    #5ce1e6 71.54%,
    #33bbcf 100%
  );
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
.hero-up {
  width: 1.5rem;
  height: 1.5rem;
}
.hero-right {
  width: 50%;
}
.hero-img {
  width: 100%;
  position: relative;
  left: 0;
  transform: translateX(8.375rem);
}
/* Stats Component */
.stats {
  margin-top: 2rem;
  margin-bottom: 11.25rem;
  padding: 0 1.136875rem;
  display: flex;
  justify-content: space-around;
}
.stats-item {
  display: flex;
  align-items: center;
  gap: 1.625rem;
}
.stats-number {
  color: #fff;
  font-size: 2.5rem;
  font-weight: 600;
}
.stats-title {
  font-size: 1.25rem;
  font-weight: 500;
  background: radial-gradient(
    64.18% 64.18% at 71.16% 35.69%,
    #def9fa 0.89%,
    #bef3f5 17.23%,
    #9dedf0 42.04%,
    #7de7eb 55.12%,
    #5ce1e6 71.54%,
    #33bbcf 100%
  );
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

/* Business */
.business {
  display: flex;
  gap: 4.875rem;
  justify-content: space-between;
}
.business-left {
  width: 50%;
}
.business-title {
  color: #fff;
  font-size: 3rem;
  font-weight: 600;
  word-wrap: break-word;
  margin-bottom: 1.5rem;
}
.business-info {
  font-size: 1.125rem;
  color: #b3b4b8;
  margin-bottom: 5rem;
}
.business-btn {
  cursor: pointer;
  padding: 1.125rem 2.125rem;
  font-size: 1.125rem;
  color: #00040e;
  font-weight: 600;
  background: radial-gradient(
    64.18% 64.18% at 71.16% 35.69%,
    #def9fa 0.89%,
    #bef3f5 17.23%,
    #9dedf0 42.04%,
    #7de7eb 55.12%,
    #5ce1e6 71.54%,
    #33bbcf 100%
  );
  border-radius: 0.625rem;
}
.business-right {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.features-item {
  padding: 1.5625rem 1.25rem;
  display: flex;
  align-items: center;
  gap: 2.5rem;
  max-width: 29.375rem;
  cursor: pointer;
  transition: all 0.7s linear;
  border-radius: 1.5rem;
}
.features-left {
  width: 10%;
}
.item-icon {
  display: grid;
  place-items: center;
  width: 4rem;
  height: 4rem;
  background-color: #01131a;
  border-radius: 50%;
}
.features-right {
  width: 90%;
  color: #ffffff;
}
.features-title {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.features-info {
  font-size: 1rem;
  color: #bdbcbf;
}
.clicked {
  background-color: #292632;
}

/* Billing */
.billing {
  margin-top: 10rem;
  display: flex;
  gap: 4.875rem;
  align-items: center;
}
.billing-left {
  width: 50%;
}
.billing-img {
  width: 100%;
}
.billing-right {
  width: 50%;
}
.billing-title {
  color: #fff;
  font-size: 3rem;
  font-weight: 600;
  word-wrap: break-word;
  margin-bottom: 1.5rem;
}
.billing-info {
  font-size: 1.125rem;
  color: #b3b4b8;
  margin-bottom: 5rem;
}
.billing-apps {
  display: flex;
  gap: 2rem;
}

/* Card Detail */
.card-detail {
  margin-top: 10rem;
  display: flex;
  gap: 4.875rem;
}
.detail-left {
  width: 50%;
}
.detail-title {
  color: #fff;
  font-size: 3rem;
  font-weight: 600;
  word-wrap: break-word;
  margin-bottom: 1.5rem;
}
.detail-info {
  font-size: 1.125rem;
  color: #b3b4b8;
  margin-bottom: 5rem;
}
.card-img {
  width: 100%;
}
.detail-right {
  width: 50%;
}

/* Testimonials */
.testimonials {
  margin-top: 10rem;
}
.testimonials-up {
  display: flex;
  align-items: center;
  gap: 8rem;
}
.up-left {
  width: 50%;
}
.testimonials-title {
  color: #fff;
  font-size: 3rem;
  font-weight: 600;
  word-wrap: break-word;
}
.up-right {
  width: 50%;
  padding-right: 8rem;
}
.testimonials-info {
  font-size: 1.125rem;
  color: #b3b4b8;
}
.testimonials-down {
  margin-top: 5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.person-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4rem 2.5rem;
  border-radius: 1.5rem;
  gap: 1.5rem;
  cursor: pointer;
  transition: all 0.7s linear;
}
.person-quotes {
  width: 2.6625rem;
  height: 1.725rem;
}
.person-info {
  font-size: 1.125rem;
  color: #c8c7ca;
}
.person-profile {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.person-img {
  width: 3rem;
  height: 3rem;
}
.person-name {
  font-size: 1.5rem;
  font-weight: 400;
  color: #fff;
}
.person-job {
  font-size: 1rem;
  color: #8e8d93;
}

/* CLients */
.clients {
  padding: 0 1rem;
  margin-top: 6.25rem;
  gap: 6.25rem;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
}
.clients-img {
  width: 100%;
}

/* CTA */
.cta {
  padding: 4.5rem 6.0625rem;
  margin-top: 3rem;
  border-radius: 1.25rem;
  display: flex;
  gap: 8.25rem;
  background-color: #201f29;
  align-items: center;
}
.cta-left {
  width: 75%;
}
.cta-title {
  color: #fff;
  font-size: 3rem;
  font-weight: 600;
  word-wrap: break-word;
  margin-bottom: 1.5rem;
}
.cta-info {
  font-size: 1.125rem;
  color: #b3b4b8;
  width: 25rem;
}
.cta-right {
  width: 25%;
}

/* Footer */
.footer {
  padding: 4.5rem 8.463125rem 1rem;
  margin-top: 5.125rem;
  background-color: #06080d;
}
.footer-up {
  gap: 1rem;
  display: flex;
}
.footer-img {
  margin-bottom: 1.875rem;
}
.footer-text {
  color: #b4b4b6;
}
.footer-info {
  width: 40%;
}
.footer-title {
  color: #fff;
  font-size: 1.125rem;
  margin-bottom: 1.5rem;
}
.footer-item:not(:last-child) {
  margin-bottom: 0.75rem;
}
.footer-link {
  color: #b4b4b6;
}
.footer-items {
  width: 20%;
}
.footer hr {
  margin-top: 2.5rem;
  border: 1px solid #3f3e45;
}
.footer-down {
  margin-top: 1.875rem;
  display: flex;
  justify-content: space-between;
}
.copyright {
  color: #9b9e9c;
}
.down-right {
  display: flex;
  gap: 1.875rem;
}
@media only screen and (max-width: 748px) {
  body {
    font-size: 14px;
    display: flex;
    flex-direction: column;
  }
  .App {
    padding: 1.25rem 1.5rem;
  }
  .nav-logo {
    width: 9.375rem;
  }
  .menu-btn {
    cursor: pointer;
  }
  .hide-right-side {
    display: block;
  }
  .right-side {
    display: none;
  }
  .nav-items {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 0.5rem;
  }
  .drop-down {
    display: block;
    overflow: hidden;
    height: 8rem;
    -webkit-transition: height 0.3s ease;
    transition: height 0.8s ease;
  }
  .close-items {
    height: 0px;
  }
  /* Hero Component */
  .hero {
    flex-direction: column;
  }
  .hero-left {
    width: auto;
    margin-bottom: 1.5rem;
  }
  .discount {
    width: 20.5rem;
  }
  .discount-text {
    font-size: 0.875rem;
  }
  .hero-title {
    font-size: 3rem;
    line-height: 1.5;
    margin-bottom: 1.5rem;
  }
  .hero-text {
    font-size: 0.875rem;
    width: 20rem;
  }
  .hero-btn {
    width: 6.75rem;
    height: 6.75rem;
    font-size: 0.875rem;
  }
  .hero-right {
    width: auto;
  }
  .hero-img {
    width: 100%;
    transform: translateX(0px);
  }
  /* Stats */
  .stats {
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 3rem;
  }
  .stats-item {
    justify-content: space-between;
  }

  /* Business */
  .business {
    flex-direction: column;
  }
  .business-left {
    width: 100%;
  }
  .business-title {
    font-size: 3rem;
    margin-bottom: 1.5rem;
  }
  .business-btn {
    cursor: pointer;
    padding: 0.875rem 1.875rem;
    font-size: 0.875rem;
  }
  .business-info {
    font-size: 0.875rem;
    margin-bottom: 3rem;
  }
  .business-right {
    width: 100%;
  }
  .features-title {
    font-size: 1rem;
  }
  .features-info {
    font-size: 0.875rem;
  }

  /* Billing */
  .billing {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 3rem;
  }
  .billing-left {
    width: 100%;
  }
  .billing-right {
    width: 100%;
  }
  .billing-info {
    font-size: 0.875rem;
    margin-bottom: 3rem;
  }
  .billing-apps {
    justify-content: center;
  }

  /* Card Detail */
  .card-detail {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 3rem;
  }
  .detail-left {
    width: 100%;
  }
  .detail-right {
    width: 100%;
  }
  .detail-info {
    font-size: 0.875rem;
    margin-bottom: 3rem;
  }

  /* Testimonials */
  .testimonials {
    margin-top: 3rem;
  }
  .testimonials-up {
    flex-direction: column;
    gap: 3rem;
  }
  .up-left {
    width: 100%;
  }
  .up-right {
    width: 100%;
    padding-right: 0;
  }
  .testimonials-info {
    font-size: 0.875rem;
  }
  .testimonials-down {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 0.5rem;
  }
  .person-item {
    padding: 2rem 1.25rem;
  }
  .person-info {
    font-size: 0.875rem;
  }
  .person-name {
    font-size: 1rem;
  }
  .person-job {
    font-size: 0.875rem;
  }

  /* Clients */
  .clients {
    margin-top: 3rem;
    gap: 3rem;
    grid-template-columns: repeat(2, 1fr);
  }

  /* CTA */
  .cta {
    padding: 1.5rem 2rem;
    gap: 1rem;
    align-items: center;
  }
  .cta-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  .cta-info {
    font-size: 0.875rem;
    color: #b3b4b8;
    width: auto;
  }
  .cta .business-btn {
    cursor: pointer;
    padding: 0.5rem;
    font-size: 0.5rem;
  }

  /* Footer */
  .footer {
    padding: 1.25rem 1.5rem;
    margin-top: 3rem;
  }
  .footer-up {
    flex-wrap: wrap;
  }
  .footer-info {
    width: 100%;
    margin-bottom: 1rem;
  }
  .footer-items {
    width: auto;
  }
  .footer-down {
    margin-top: 0.875rem;
  }
  .copyright {
    font-size: 0.675rem;
  }
  .down-right {
    gap: 0.875rem;
  }
}
